import React from 'react';
import styled from 'styled-components';
import themeDefaultProps from '../../../themes/dark';

export const CaptionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

const Title = styled.h1`
  font-family: ${props => props.theme.typography.type.primary};
  font-weight: ${props => props.theme.typography.weight.black};
  font-size: ${props => props.theme.typography.size.m3}px;
  color: ${props => props.theme.color.primary};
  line-height: 32px;
  margin-bottom: 0.5rem;

  @media (min-width: ${props => props.theme.breakpoint * 1}px) {
    font-size: ${props => props.theme.typography.size.l3}px;
    line-height: 52px;
    margin-bottom: 0.5rem;
  }

  @media (min-width: ${props => props.theme.breakpoint * 2}px) {
    font-size: 56px;
    line-height: 60px;
    margin-bottom: 0.75rem;
  }
`;
Title.defaultProps = {
  theme: themeDefaultProps,
};

export const Caption = () => (
  <CaptionWrapper>
    <Title>404</Title>
    <p>Algo no anda bien aquí :(</p>
  </CaptionWrapper>
);
