import React from 'react';
import PageLayout from '../../layout/PageLayout';
import { SocialGraph } from '../../basics';
import { Caption } from './Caption';

export function PureNotFoundScreen({ ...props }) {
  return (
    <PageLayout {...props}>
      <SocialGraph title="404: Not found" />
      <main>
        <Caption />
      </main>
    </PageLayout>
  );
}

export default function NotFoundScreen(props) {
  return <PureNotFoundScreen {...props} />;
}
